import React, { useEffect } from 'react'
import Footer from 'src/components/layout/Footer'
import { Helmet } from 'react-helmet'
import smoothscroll from 'smoothscroll-polyfill'
import SimplifiedHeader from './SimplifiedHeader'

interface ISimplifiedLayoutProps {
  lang: string
  dir: string
  children: JSX.Element | JSX.Element[]
}

const detectKeyboardUser = () => {
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse')
  })

  document.body.addEventListener('keydown', (event) => {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse')
    }
  })

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse')
  })
}

const SimplifiedLayout: React.FC<ISimplifiedLayoutProps> = ({
  lang,
  dir,
  children,
}) => {
  useEffect(() => {
    smoothscroll.polyfill()
    detectKeyboardUser()
  }, [])

  return (
    <div className="font-roag overflow-hidden tracking-wide">
      <Helmet
        htmlAttributes={{ lang }}
        titleTemplate="%s | IFAB"
      >
        <meta charSet="utf-8" />
        <link
          as="font"
          href="/fonts/Roag-Regular.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          as="font"
          href="/fonts/Roag-Bold.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <link
          as="font"
          href="/fonts/Roag-UltraLight.woff2"
          rel="preload"
          crossOrigin="anonymous"
        />
        <script
          defer
          data-domain="theifab.com"
          src="https://plausible.io/js/plausible.js"
        />
      </Helmet>
      <SimplifiedHeader />
      <div className="relative">
        <main className="main" dir={dir}>{children}</main>
      </div>
      <Footer />
    </div>
  )
}

export default SimplifiedLayout
