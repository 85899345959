import React, { FC, useEffect } from 'react'
import Logo from 'src/components/Logo'

const showHeaderWhenScrollingUp = () => {
  let previousScrollPosition = 0

  return () => {
    const header = document.getElementById('header')
    const headerContainer = document.getElementById('header-container')
    const headerHeight = header.offsetHeight
    const headerPlaceholder = document.getElementById('header-placeholder')
    headerPlaceholder.style.height = `${headerHeight}px`
    headerContainer.style.position = 'fixed'
    const currentHeaderOffset = parseInt(headerContainer.style.top, 10) || 0
    const pixelsScrolled = window.scrollY - previousScrollPosition
    const thisScrollOffset = pixelsScrolled * -1
    const newHeaderOffset = currentHeaderOffset + thisScrollOffset
    const newPositon = Math.min(Math.max(newHeaderOffset, headerHeight * -1), 0)

    if (window.scrollY < 0) {
      headerContainer.style.top = `${newPositon - window.scrollY}px`
    } else {
      headerContainer.style.top = `${newPositon}px`
    }

    previousScrollPosition = window.scrollY
  }
}

const Header: FC = (): JSX.Element => {
  useEffect(() => {
    const scrollFunction = showHeaderWhenScrollingUp()

    window.addEventListener('scroll', scrollFunction)

    return () => {
      window.removeEventListener('scroll', scrollFunction)
    }
  })
  return (
    <>
      <div id="header-placeholder" />
      <div id="header-container" className="relative left-0 right-0 top-0 z-20">
        <header id="header" className="shadow-lg bg-white">
          <div className="relative mx-auto flex justify-center items-center">
            <div className="px-4 py-3">
              <Logo />
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default Header
